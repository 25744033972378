import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import { VNode } from "vue/types/umd";

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify, //style framework
  render: (h): VNode => h(App),
  i18n, //translation support
}).$mount("#app");
