
















































































































































































































































import Vue from "vue";
import { countdownElement, appData } from "src/scripts/types";

export default Vue.extend({
  name: "Home",
  components: {},
  data: () => ({
    allCountdowns: [] as countdownElement[],
    now: new Date(),
    nowTime: 0,
    interv: null as NodeJS.Timeout | null,
    nextID: 0,
    newCountdown: {
      dateErrorDialog: false,
      dialog: false,
      name: "",
      step: 1,
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      startTime: new Date().toLocaleTimeString(),
      endTime: new Date(Date.now() + 1000 * 60 * 15).toLocaleTimeString(),
    },
    deleteCountdown: {
      dialog: false,
      key: 0,
    },
  }),
  mounted() {
    this.interv = setInterval(() => {
      this.now = new Date();
      this.nowTime = this.now.getTime();
    }, 100);
    if (localStorage.appData) {
      let allUnparsed = JSON.parse(localStorage.appData) as appData;

      for (let el of allUnparsed.allCountdowns) {
        el.startDate = new Date(el.startDate);
        el.endDate = new Date(el.endDate);
      }
      this.nextID = allUnparsed.nextID;
      this.allCountdowns = allUnparsed.allCountdowns;
    }
  },
  methods: {
    dateToMin: function (date: Date): number {
      let min = Math.ceil(((date.getTime() - this.nowTime) / 1000 / 60) % 60);
      return min < 0 ? 0 : min;
    },
    dateToHour: function (date: Date): number {
      let hour = Math.floor(
        ((date.getTime() - this.nowTime) / 1000 / 60 / 60) % 24
      );
      return hour < 0 ? 0 : hour;
    },
    dateToDay: function (date: Date): number {
      let day = Math.floor(
        (date.getTime() - this.nowTime) / 1000 / 60 / 60 / 24
      );
      return day < 0 ? 0 : day;
    },
    percent: function (startDate: Date, endDate: Date): number {
      return (
        ((this.nowTime - startDate.getTime()) /
          (endDate.getTime() - startDate.getTime())) *
        100
      );
    },
    resetNewCountdown: function (): void {
      this.newCountdown = {
        dateErrorDialog: false,
        dialog: false,
        name: "",
        step: 1,
        startDate: new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
        startTime: new Date().toLocaleTimeString(),
        endTime: new Date(Date.now() + 1000 * 60 * 15).toLocaleTimeString(),
      };
    },
    saveCountdown: function (): void {
      let startDate = new Date(
        this.newCountdown.startDate + "T" + this.newCountdown.startTime
      );
      let endDate = new Date(
        this.newCountdown.endDate + "T" + this.newCountdown.endTime
      );
      if (startDate >= endDate) {
        this.alertDateError(startDate, endDate);
        return;
      }
      this.allCountdowns.push({
        key: this.nextID++,
        name: this.newCountdown.name,
        startDate: startDate,
        endDate: endDate,
      });
      this.saveToLocalStorage();
      this.resetNewCountdown();
    },
    startDeleteCountdown: function (key: number): void {
      this.deleteCountdown.dialog = true;
      this.deleteCountdown.key = key;
    },
    deleteCountdownItem: function (): void {
      this.allCountdowns = this.allCountdowns.filter(
        x => x.key != this.deleteCountdown.key
      );
      this.saveToLocalStorage();
      this.deleteCountdown.dialog = false;
    },
    saveToLocalStorage: function (): void {
      localStorage.appData = JSON.stringify({
        nextID: this.nextID,
        allCountdowns: this.allCountdowns,
      } as appData);
    },
    alertDateError: function (startDate: Date, endDate: Date): void {
      this.newCountdown.dateErrorDialog = true;
    },
  },
});
