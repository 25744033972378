
























import Vue from "vue";
import AppBar from "@/components/AppBar.vue";

export default Vue.extend({
  name: "App",

  components: {
    AppBar,
  },

  data: () => ({
    testProp: null as string | null,
    registration: null as ServiceWorkerRegistration | null,
    updateExists: false,
    refreshing: false,
    updateDialog: false,
  }),
  created() {
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },
  mounted() {
    // Do sth on App mount
  },
  methods: {
    test: function (msg: string): void {
      this.testProp = msg;
    },
    goBack: function (): void {
      this.$router.go(-1);
    },
    updateAvailable(event: CustomEvent | Event): void {
      this.registration = (event as CustomEvent).detail;
      if (
        !localStorage.skipUpdateDate ||
        localStorage.skipUpdateDate <
          Math.floor(new Date().getTime() / (24 * 60 * 60 * 1000))
      ) {
        this.updateDialog = true;
      }
      this.updateExists = true;
    },
    refreshApp(): void {
      this.updateExists = false;
      this.updateDialog = false;
      localStorage.removeItem("skipUpdateDate");
      if (!this.registration || !this.registration.waiting) return;
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    skipUpdate(): void {
      localStorage.skipUpdateDate = Math.floor(
        new Date().getTime() / (24 * 60 * 60 * 1000)
      );
      this.updateDialog = false;
    },
  },
});
