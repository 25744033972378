// VUETIFY CONFIGURATION

import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/es5/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: colors.cyan.base,
        secondary: colors.grey.darken1,
        accent: colors.amber.base,
        error: colors.red.accent3,
      },
    },
  },
});
